<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      :showAdd="$hasAccess('market.coupon/saveurl')"
      addName="创建"
      @getList="getList"
      @clearSearch="clearSearch"
      @addItemInTable="addOne"
    >

      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
      </el-form-item>
      <!--表格内容-->
      <vxe-table-column slot="table-item" field="start_time" title="开始时间" align="center" min-width="80px"/>
      <vxe-table-column slot="table-item" field="end_time" title="结束时间" align="center" min-width="80px"/>
      <vxe-table-column slot="table-item" field="coupon_id" title="优惠券ID" align="center" min-width="50px"/>
      <vxe-table-column slot="table-item" field="num" title="数量" align="center" min-width="50px"/>
      <vxe-table-column slot="table-item" field="token" title="标识" align="center" min-width="80px"/>
      <vxe-table-column slot="table-item" field="url" title="领券地址" align="center" min-width="150px"/>
      <vxe-table-column
        slot="table-item"
        title="操作"
        align="center"
        width="400px"
      >
        <template v-slot="{ row }">
          <el-button
            size="small"
            plain
            @click="deleteOne(row)"
          >删除</el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 新增 -->
    <el-dialog
      title="创建"
      :visible.sync="addDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <el-form
        :model="addDialogFormData"
        :rules="rules"
        ref="addForm"
        label-width="100px"
        class="form"
      >
        <el-form-item
                label="开始时间"
                prop="select_time"
        >
          <el-date-picker class="from-item-width" unlink-panels v-model="addDialogFormData.select_time" type="datetime" placeholder="开启时间" ></el-date-picker>
        </el-form-item>
        <el-form-item
                label="结束时间"
                prop="select_time2"
        >
          <el-date-picker class="from-item-width" unlink-panels v-model="addDialogFormData.select_time2" type="datetime" placeholder="结束时间"></el-date-picker>
        </el-form-item>
        <el-form-item
                label="标识"
                prop="token"
        >
          <el-input
                  v-model="addDialogFormData.token"
                  style="width: 80%;"
          ></el-input>
        </el-form-item>
        <el-form-item
                label="优惠券ID"
                prop="coupon_id"
        >
          <el-input
                  v-model="addDialogFormData.coupon_id"
                  style="width: 80%;"
          ></el-input>
        </el-form-item>
        <el-form-item
                label="发券数量"
                prop="num"
        >
          <el-input-number :min="1" :max="10" :step="1" v-model="addDialogFormData.num" clearable />
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveAdd()"
          v-if="$hasAccess('market.coupon/add')"
        >保 存
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Tables from '@/components/tables'
export default {
  name: 'market-coupon-url',
  components: {
    Tables
  },
  data () {
    return {
      // 表格信息
      tableName: '领券',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {},
      selecttime: {},
      // 二维码弹窗
      showQrCode: false,
      // 新增
      addDialogShow: false,
      addDialogFormData: {},
      rules: {
        token: [
          {required: true, message: '请输入此次标识', trigger: 'change'}
        ],
        coupon_id: [
          {required: true, message: '请输入优惠券ID', trigger: 'change'}
        ],
        num: [
          {required: true, message: '请输入发放数量', trigger: 'change'}
        ]
      },
    }
  },
  computed: {
    editDisabled () {
      for (let item of this.editNeedData) {
        if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
          return false
        }
      }
      return false
    }
  },
  mounted () {
  },
  activated () {
  },
  methods: {
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await this.$api.Market.GetCouponUrlList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {}
    },
    // 重置form
    resetFormData () {
      this.addDialogFormData = {

      }
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields()
      }
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields()
      }
    },
    // 新增
    addOne () {
      this.resetFormData()
      this.addDialogShow = true
    },
    // 删除
    deleteOne (row) {
      this.$confirm('确定要删除吗', '提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const params = {
          token: this.$store.state.user.token,
          id: row.id
        }
        await this.$api.Market.DeleteCouponUrl(params)
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        this.$refs.xTable.refreshTable()
      })
    },
    // 关闭弹窗
    closeDialog () {
      this.addDialogShow = false
    },
    // 新增保存
    saveAdd () {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          if (this.addDialogFormData.select_time!=undefined) {
            this.addDialogFormData.start_time = parseInt(this.addDialogFormData.select_time.getTime() / 1000);
            this.addDialogFormData.end_time = parseInt(this.addDialogFormData.select_time2.getTime() / 1000);
          }
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.addDialogFormData)
          }
          await this.$api.Market.AddCouponUrl(params)
          this.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .qrcode {
    .device-name {
      text-align: center;
    }

    .qrcode-url {
      width: 250px;
      height: 250px;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }
  }
  .qr-code {
    display: flex;

    .qr-code-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 530px;
      height: 300px;

      .qrcode-content {
        height: 300px;
      }
    }
  }
</style>
